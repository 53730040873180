@media screen and (max-width: 479px) {
    .preloadText {
        font-size: 1.3em !important;
    }

    .startNotice {
        font-size: 1em !important;
        color: rgb(105, 105, 105);
    }

    .preloadWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .preloadContentContainer {
        width: 100%;
        margin: 30px !important;
        padding: 0px !important;
    }

    .preloadHeading {
        font-size: 3em !important;
    }

    
  /*   .preloadBackground {
        position: fixed;
        top: -30%;
        right: 30px !important;
        opacity: .3 !important;
    } */

    .preloadOverlay {
        margin: 0;
        padding: 0 30px 0 30px !important;
    }

    .bgCurvesAnimation > svg {
        height: 100vh !important;
        width: 100vw !important;
        transform: scale(3) !important;
    }

}

.preloadWrapper {
    width: 100vw;
    height: 100vh;
    display: flex;

    display: flex;
    flex-direction: column;
    text-align: start;
    z-index: 1000 !important;
    position: fixed;
    top: 0;
}

.overlayUpper {
    height: 50vh;
    width: 100%;
    background: rgb(0, 0, 0);
}

.overlayLower {
    height: 50vh;
    width: 100%;
    background: rgb(0, 0, 0);
}

.overlayFrosted {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.27);
    z-index: 2;
}

.preloadContentContainer {
    width: 100%;
    margin: 60px;
    padding: 30px;
    z-index: 3;
}

.preloadText {
    color: rgb(255, 254, 254); 
    font-size: 1.5vw;
    font-weight: 300;

    font-style: normal;
    margin-top: 1.5em;
    letter-spacing: 1px;
/*     -webkit-text-fill-color: black;
    -webkit-text-stroke: 2px; */
}

.preloadHeading {
    font-size: 4vw;
    color: white;
   
    margin-top: .5em;

    font-weight: 400;

    font-style: normal;
}

.hideComponent {
    display: none;
    visibility: hidden;
    opacity: 0;
}

.startNotice {
    font-size: .8vw;
    color: rgb(128, 128, 128);
    opacity: 0;
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startNoticePlaceHolder {
    visibility: hidden;
}

.elementHidden {
    opacity: 0;
    visibility: hidden;
    display: none;
}

.preLoadHidden {
    opacity: 0;
    visibility: hidden;
}

.tapAnimation {
    width: 50px;
    height: 50px;
    opacity: .5;
    color: white;
}

.wavesAnimation {
    width: 100%;
    height: 50px;
    opacity: 0;
}

.preloadOverlay {
    position: fixed;
    width: 100%;
    bottom: 30px;
    padding: 0 60px 0 60px;

    z-index: 3;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.lottieOverlay {
    position: fixed;
    width: 100%;
    bottom: 30px;
    padding: 0 60px 0 60px;

    z-index: 2;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.headphonesIcon {
    width: 30px;
    height: 30px;
    transition: 0.25s all ease;
    color: rgb(255, 255, 255) !important;
    opacity: 0.5;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloadBackground {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.bgCurvesAnimation {
    z-index: 1;
    min-width: 300px;
    min-height: 300px;
}



.introVideoOverlay {
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 10px;
    border-radius: 50%;
    color: black;
    z-index: 3 !important;
   /*  border: 1px solid black; */

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease all;

    right: -60px; 
}

.introVideoOverlay:hover {
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 10px;
    border-radius: 50%;
    color: white;
    z-index: 3 !important;
    border: 1px solid black;
    background: black;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.skipButton {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

/* Enter Button*/

.btn {
    position: relative;
    color: rgb(251, 251, 251);
    width: 200px;
    height: 50px;
    line-height: 64px;
    transition: all 0.3s;
    letter-spacing: 1px;
    font-size: 14px;
}

.btn:hover {
    cursor: pointer;
}

.btn > span {
    transition: all 0.3s;
    transform: scale(1, 1);
}

.btn::before, .btn::after {
    content: '';
    position: absolute;
    transition: all 0.3s;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.btn-six::before {
    opacity: 0;
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.3, 1.3);
    border: none;
}
  
.btn-six:hover::before {
    opacity: 1;
    transform: scale(1, 1);
}

.btn-six::after {
    transition: all 0.3s;
}

.btn-six:hover::after {
    transform: scale(0, 0);
    opacity: 0;
}