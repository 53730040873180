
/* Setting style vars -------------------------------------  */

:root{
    --dark: rgb(0, 0, 0);
    --text-light: rgb(255, 255, 255);
    --secondary-color: rgb(74, 17, 139);
    --background-color: rgb(33, 33, 33);
    --lightgrey-text: rgb(211, 211, 211);
}

/* video intro section -------------------------------------  */

.LoadingVideoContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 3;
}

.IntroVideoContainerSeq1 {
    width: 100vw;
    height: 101vh;
    top: 0;
    overflow-y: scroll;
    visibility: visible;
    z-index: 2;
    position: fixed;
}

.IntroVideoContainerSeq2 {
    width: 100vw;
    height: 101vh;
    top: 0; 
    visibility: visible;
    z-index: 1;
    position: fixed;
}

.showSeq {
    opacity: 1;
}

.gmIntroVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: white;
}

#landingWrapper {
    width: 100%;
    height: 100%;
    background: white;
}

.loadingyNotice {
    position: fixed;
    bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    font-size: 12px;
}

.scrollToPlayNotice {
    position: fixed;
    bottom: 0px;
    /* right: calc(50% + 100px);  */
    transform: rotate(270deg);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1000;
}

.scrollAnimation {
    width: 300px;
    height: 300px;
    transform: rotate(90deg) scaleX(1);
}

.scrollToPlayNoticeWrapper {
    position: fixed;
    left: -250px;
    bottom: 0px;
}

.scrollToPlayNoticeContainer {

}

.videoScrollAnimation {
    min-height: 300px;
    min-width: 300px;
}

.loading-color {
    color: white;
}

.seq-one-color {
    color: black;
} 

.elementHidden {
    opacity: 0;
    visibility: hidden;
    display: none;
}

/*  Navigation Container ------------------------------------------- */

#dub3NavContainer {
    position: fixed; /* Set the navbar to fixed position */
    color: var(--text-light);
    font-size: 1em;
    overflow: hidden;
    z-index: 333 !important;
    opacity: 1;
}

/*  Navigation Links ------------------------------------------- */


.dub3Logo {
    position: fixed;
    top: 25px;
    left: -300px;
    width: 75px;
    height: 75px;
    transition: 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
    z-index: 3 !important;
}

.dub3Logo:hover {
    filter: blur(.5px);
    transform: scale(1.1);
    cursor: pointer;
}

span.dub3NavLink {
    position: relative;
    display: block;
    cursor: pointer;
    width: 100px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: fixed; 
    bottom: 75px;
    transform: rotate(270deg);  
}

span.dub3NavLink:before, span.dub3NavLink:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 3px;
    top: 40%;
    margin-top: -1px; 
    background: white;
}
  
span.dub3NavLink:before {
    left: -2.5px;
}

span.dub3NavLink:after {
    right: 2.5px;
    transition: width 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}
  
span.dub3NavLink:hover:before {
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
  
span.dub3NavLink:hover:after {
    background: transparent;
    width: 100%;
    transition: 0s;
}
  
.closeIconContainer {
    position: fixed;
    top: 20px;
   /*  top: calc(100vh - 80px); */
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50px;
    height: 50px;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 25;
}

.closeIconContainer2 {
    position: fixed;
    top: 30px;
   /*  top: calc(100vh - 80px); */
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50px;
    height: 50px;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 20;
}

.closeIcon {
    width: 20px;
    height: 20px;
    transition: 0.25s all ease;
    color: black !important;
    color: #1f1f1f !important;
}

.closeIcon:hover {
    transform: rotate(90deg);
    color: var(--lightgrey-text) !important;
    cursor: pointer;
}

.socialIconContainer {
    position: fixed;
    bottom: 18px;
    /* top: calc(100vh - 80px); */
    right: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30px;
    height: 50px;
    align-items: center;
    opacity: 0;
    visibility: hidden;
}

.footerSocialIcon {
    width: 20px;
    height: 20px;
    transition: 0.25s all ease;
    color: black !important;
}

.footerSocialIcon:hover {
    transform: scale(1.1);
    color: var(--lightgrey-text) !important;
    cursor: pointer;
}


/*  Experience CANVAS ------------------------------------------- */

.webglCanvas {
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.webglCanvas > canvas {
    width: 100%;
    height: 100%;
}

 /*  Landing Page styling ------------------------------------------- */

.landing-wrapper {
    opacity: 1;
}

.page {
    z-index: 333;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.page-wrapper {
    position: relative;
}

/* Hero section ------------------------------------------- */

@media screen and (max-width: 479px) {
    .hero-wrapper {
        position: relative;
        height: 100%;
        width: calc(100% - 10px) !important;
        margin: 0 auto;
    }

    .hero-main-title {
        font-size: 1.3em !important;
    }
}

.hero {
    width: 100vw;
    height: 100vh;
}

.hero-wrapper {
    position: relative;
    height: 100%;
    width: calc(100% - 180px);
    margin: 0 auto;
}

.hero-main {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center; 
} 

.hero-main-title {
    color: rgb(252, 250, 250);
    font-size: 3vw;
    line-height: 1.1em;
    text-align: center;
    text-shadow: 1px 1px 15px rgb(117, 117, 117);
    color: var(--text-light)
} 

/* Call to action section ------------------------------------------- */

@media screen and (max-width: 479px) {
    .CTA-wrapper {
        position: relative;
        height: 100%;
        width: calc(100% - 30px) !important;
        margin: 0 auto;
    }

    .callToAction-main-title {
        font-size: 1.5em !important;
    }

    .callToActionLink {
        font-size: 1.3em !important;
    }
}

.CTA {
    width: 100vw;
    height: 100vh;
}

.CTA-wrapper {
    position: relative;
    height: 100%;
    width: calc(100% - 300px);
    margin: 0 auto;
}

.CTA-main {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
} 

.callToAction-main-title {
    color: rgb(252, 250, 250);
    font-size: 3vw;
    line-height: 1.1em;
    text-align: center;
    text-shadow: 1px 1px 15px rgb(117, 117, 117);
    color: var(--text-light)
} 

.callToActionLink {
    margin-top: 1em;
    font-size: 1.5vw;
    color: var(--lightgrey-text);
    display: inline-block;
    position: relative;
    transition: all 0.25s ease-out;
    z-index: 9;
}

.callToActionLink:hover {
    color: white;
}

.callToActionLink:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--lightgrey-text);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .callToActionLink:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

/*  Section styling ------------------------------------------- */

@media screen and (max-width: 479px) {
    .sectionTitle {
        font-size: 1.1em !important;
    }
    
    .sectionText {
        font-size: 1.1em !important;
    }

    .section {
        width: calc(100% - 70px) !important;
        margin: 0 auto !important;
    }

    .threeHeadingTitle {
        font-size: 1.5em !important;
    }

    .threeHeadingText {
        font-size: 1em !important;
    }

    #sectionThree {
        text-shadow: 5px 5px 20px rgb(6, 6, 6) !important; 
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)) 
    }
}


.section-margin {
    height: 500px;
    width: 100%;
}

.section {
    width: 50%;
    padding: 250px 7%;
    margin: 0;
    color: var(--text-light);
    text-shadow: 1px 1px 15px rgb(117, 117, 117);
}

.left {
    margin-right: auto;
}

.right {
    margin-left: auto;
}


/*  New Contact form styling  ------------------------------------------- */

@media screen and (max-width: 479px) {
    .contactFooterContainer {
        padding: 10vh 40px 40px 40px !important;
        overflow: visible !important;
    }
    
    .contactHeadline {
        font-size: 2em !important;
    }

    .footerFormElementContainer {
        flex-direction: column !important;
    }

    .contactFormElement {
        width: 100% !important;
        margin-bottom: 50px;
    }

    .footerFormContainer {
        margin-top: 30px !important;
    }
}

.transitionSlide {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: grey;
    top: 100%;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1) all;
    z-index: 10 !important;

    /* Glassmorphism effect*/
    background: rgba(255, 255, 255, 0.11);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
}

.contactFooterContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgb(255, 255, 255);    
    top: 100%;
    z-index: 20;
    padding: 5%;
}

.contactHeadline {
    color: black !important;
    font-size: 4vw;
    line-height: 1.1em;
}

.footerFormContainer {
    margin-top: 30px;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    color: black;
}

.footerFormContainer > div > p {
    letter-spacing: .5px;
}

.footerFormElementContainer {
    display: flex;
    flex-direction: row;
    overflow: visible !important;
}

.contactFormElement {
    width: 300px;
    height: 100%;
    margin-right: 60px;
    font-size: 16px;
}

.dubFormContainer > input[type=email], textarea {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid black;
    min-height: 40px;
    padding: 5px 5px 0 0px;
}

.dubFormContainer > input, textarea {
    margin-bottom: 20px;
    font-size: 16px !important;
}

.dubFormContainer > input[type=email]:focus, textarea:focus {
    outline: none;
}


::placeholder {
    color: rgb(211, 211, 211);
}

.formInputButton {
    width: 100%;
    min-height: 30px;
    background: none;
    border: 1px solid black;
    transition: .25s ease all;
    color: black;
}

.formInputButton:hover {
    color: white;
    background: black;
}

.emailLink {
    color: lightgrey;
    transition: .25s ease all;
}

.emailLink:hover {
    color: black;
}

.successMessage {
    opacity: 0;
    visibility: hidden;
    display: none;
    margin-top: 1em;
    color: black;
    background: rgb(180, 247, 81);
    text-align: center;
    padding: 5px;
    font-size: 13px;
    transition: .5s ease all;
}

.active {
    opacity: 1;
    visibility: visible;
    display: block;
}

/*  Cookie notice  ------------------------------------------- */

.cookieNoticeContainer {
    position: fixed;
    right: -100%;
    bottom: 60px;
    margin: 30px;
    padding: 20px;
    width: 250px;
    height: 120px;
    border-radius: 15px;

    background: rgba(255, 255, 255, 0.24);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border: 0.5px solid white;

    z-index: 5;
    font-size: 0.8em;
    line-height: 1em; 
    color: white;

    text-shadow: 1px 1px 15px rgb(117, 117, 117, 0.3);
    animation-fill-mode: forwards;  

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cookieLinkContainer {
    display: flex;
    justify-content: space-between;
}

.cookieLinkContainer > .callToActionLink{
    font-size: 1em !important;
}

.animateCookieNotice {
    animation: fade-in-right 0.5s ease forwards;
    animation-delay: 1s;
}

.privacy-link {
    color: grey;   
    transition: 0.5s ease all;
}

.privacy-link:hover {
    color: white;   
}

@keyframes fade-in-right {
    0% {
      right: -100%;
    }
    100% {
      right: 0%;
    }
  }

/* underline link ----------------------------------------------- */

.dubLink {
    color: var(--lightgrey-text) !important;
    display: inline-block;
    position: relative;
    transition: all 0.5s ease-out;
}

.dubLInk:hover {
    color: white;
    cursor: pointer;
}

.dubLInk:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--lightgrey-text);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.dubLInk:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.scrollAnimation {
    display: none;
}



.audioControls {
    position: fixed;
    bottom: 30px;
    left: 40px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    transition: 0.3s ease all;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: .5rem;
    box-sizing: border-box;

    z-index: 2 !important;
}

.audioControls:hover { 
    background: rgb(147, 147, 147);
}

.playing__bar {
    display: inline-block;
    background: white;
    width: 15%;
    height: 100%;
    animation: 0.5s ease all;
    overflow: hidden !important;

  }
  
.is_playing {
    animation: up-and-down 2s ease infinite alternate;
}

.not_playing {
    height: 10% !important;
}
  
  .playing__bar1 {
    height: 60%;
  }
  
  .playing__bar2 {
    height: 30%;
    animation-delay: -2.2s;
  }
  
  .playing__bar3 {
    height: 75%;
    animation-delay: -3.7s;
  }

  .playing__bar4 {
    height: 50%;
    animation-delay: -1.5s;
  }

  .playing__bar5 {
    height: 25%;
    animation-delay: -2.4s;
  }
  
  @keyframes up-and-down {
    10% {
      height: 30%;
    }
    
    30% {
      height: 100%;
    }
    
    60% {
      height: 50%;
    }
    
    80% {
      height: 75%;
    }
    
    100% {
      height: 60%;
    }
  }

    
  @keyframes bar-down {
    0% {
       height: 100%;
    }
    100% {
      height: 0%;
    }
  }


/* Footer styling */

@media screen and (max-width: 900px) {
    .dubFooter {
        bottom: 70px !important;
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 0.5em !important;
    }
}

.dubFooter {
    position: fixed;
    bottom: 35px;
    width: 100vw;
    height: auto;
    color: grey !important;
    z-index: 2;
    text-decoration: none;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    font-size: 0.7em;

    visibility: hidden;
    opacity: 0;
}

.footerLinks {
    display: flex;
}

.footerLink {
    color: grey !important;
    text-decoration: none;
    margin-left: 10px;
    transition: 0.5s ease;
}

.footerLink:hover {
    color: rgb(225, 225, 225) !important;
    text-decoration: none;
    margin-left: 10px;
}

/* Audio control button */

@media screen and (max-width: 479px) {
    .audioControls {
        visibility: hidden;
    }

    .emailLink{
        font-size: 1.3em !important;
      }

    .heroHeadingCTA {
        font-size: 1em !important;
    }

    .dubFooter {
        bottom: 30px !important;
    }
    
}
