.animationGui {
    position: fixed;
    width: 150px;
    height: 350px;
    padding: 10px;
    margin: 20px;
    top: 0;

    border-radius: 25px;
    
    background: rgb(235, 235, 235, 0.3);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 10;
    border: 1px solid rgba(255, 255, 255, 0.438);
}

.guiButton {
    height: 50px;
    width: 100%;
    color: black;
    border-radius: 50px;
    margin-top: 10px;
    transition: all 0.3s ease;
    border: 1px solid white;
}

.guiButton:hover {
    background: rgb(212, 212, 212);
    cursor: pointer;
}

.textOverlayWrapper {
    position: relative;
    opacity: 0;
    top: 0;
} 

.FiberCanvas {
    position: fixed;
    width: 100%;
    height: 100%;
}

.fiberExperienceContainer {
    width: 100vw;
    height: 100vh;
    opacity: 0;
}

.loader {
    color: white;
    font-size: 3em;
}

.fiberHidden {
    opacity: 0;
    visibility: hidden;
}