* {
  font-family: "Dela Gothic One", Helvetica;
  font-weight: 400;
  font-style: normal;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
    overflow: hidden; 
   /*  cursor: none !important; */
}

body:hover {
  cursor: default;
}

Link:hover, a:hover, label:hover {
  cursor: pointer;
}

html {
  background: rgb(28, 28, 28);
  background: #141414
}

body {
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  background: rgb(28, 28, 28);
}

.form {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.form-container {
  background: black;
  display: grid;
  place-items: center;
}

/*.form {
  max-width: 250px;
  background: grey;
  padding: 20px;
}*/

/* .form input {
  width: 300px;
  height: 30px;
  margin: 2px;
} */

.msg {
  margin: 20px;
  border-radius: 10px;
  padding: 10px;
  border: purple;
  max-width: 225px;
}

.submit-btn {
  max-width: 200px;
  margin-top: 20px;
  padding: 10px;
  font-size: small;
  border-radius: 10px;
  border: none;
  background-color: rgba(245, 245, 245, 0.788);
  transition: all 300ms ease-in-out;
}

.submit-btn:hover{
  background-color:#896EB1;
  transition: all 300ms ease-in-out
}

.canvas {
  width: 100vh;
  height: 100vw;
  background: rgb(24, 22, 22);
  position: fixed;
  overflow: hidden;
}

.canvasCupWidget {
  width: 300px;
  height: 300px;
}

#nav {
	position: fixed;
	top: 75px;
  left: 180px;
	width: 100%;
	z-index: 100;
	display: block;
  color: white;
}

.nav-ul {
  position: absolute ;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
}

#nav-li {
  list-style: none;
  width:  150px;
}

.nav-link {
  display: block;
  padding: 13px;
  text-align: center;
  color: #fff;
  transition: 0.5s;
  text-decoration: none;
  font-size: 17px;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-align: left;
}


.overlay-heading {
  color: '#808080';
  position: 'absolute'; 
  top: 50;
  left: 40; 
  font-size: '13px';
}

.underline:after{
  content: '';
  position: absolute;
  width: 0; height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #808080;
  transition: width .2s ease;
  -webkit-transition: width .2s ease;
}
 
.underline:hover:after{
  width: 0%;
  left: 0;
  background: #808080;
}

.no-link-styling {
  text-decoration: none;
  color: #808080;
}

.represent {
  color: white;
  font-size: small;
  text-shadow: 0px 0px 6px rgba(0,0,0,1);

}

.dizz {
  color: white;
  font-weight: bold;
  text-shadow: 0px 0px 6px rgba(0,0,0,1);

}

.msg-button {
  background: grey;
  padding: 20px;
  border-radius: 50%;
}

.emailLink{
  font-size: 3em;
}

.chatButton {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    text-decoration: none;
    color: white;
    background-color: white;
    z-index: 15 !important;
    box-shadow: 0 4px 30px rgb(0 0 0 / 40%);
}

.chatButton:hover {
  background-color: white;
}

.btnIcon {
  animation: fadeIn 0.5s; 
  fill: grey;
  width: 15px;
  height: 15px;
}

.btnIconClose {
  animation: fadeIn 0.5s; 
  fill: grey;
  width: 30px;
  height: 30px;
}


.fadeIn {
  animation: fadeIn 1s; 
}

.videoTag {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  95% { opacity: 1 }
  100% { opacity: 0; }
}

.hidden{
  display: none;
}

.homeContainer {
  height: 100vh;
  width: 100vw;
}

@keyframes fade-out {
  from { opacity: 1 }
    to { opacity: 0 }
}

@keyframes fade-in {
  0% { opacity: 0.2; }
  100% { opacity: 0.75; }
}

#fader.fade-out {
  opacity: 0;
  animation-name: fade-out;
}

#fader.fade-in {
  opacity: 1;
  animation-name: fade-in;
}

.fadeIn {
  animation: fadeIn 1s; 
}

.VideoOverlay {
  position: fixed;
  display: grid;
  place-items: flex-end center;
  width: 100vw;
  height: 100vh;
  z-index: 20;
}

.VideoOverlay h1 {
  font-size: 1em;
  letter-spacing: 0.25px;
  opacity: 0.75;
}

.PopUpContainer {
  position: absolute;
  top: 95vh;
  display: grid;
  place-items: center;
  animation: fade-in 10s; 
}

.skipIcon {
  width: 30px;
  height: 30px;
  margin: 5px;
  fill: white;
  z-index: 0;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  background: rgb(143, 143, 143);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transition: all 0.4s ease-in-out;
  background: rgb(90, 90, 90);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(117, 117, 117, 1);
}

.react-chatbot-kit-chat-input {
  font-size: 16px !important;
}

[data-predefined-style="true"] small {
  display: inline-block;
  font-size: 1.8rem;
  line-height: 1.2;
  font-family: "Diatype Variable", Icons;
  font-style: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

[data-predefined-style="true"] small a {
  color: rgba(0, 0, 0, 0.85);
  border-bottom-width: 0em;
}

[data-predefined-style="true"] small a:hover {
  color: rgba(0, 46, 207, 0.8);
  border-bottom-width: 0em;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #c4c4c4;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
} 

.menu-btn {
  position: relative;
  top: 30px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all 0.5s;
  opacity: 0;
  z-index: 2;
}

.menu-btn-burger {
  z-index: 0;
  width: 50px;
  height: 6px;
  position: absolute;
  background: white;
  /* border-radius: 5px; */
  transition: all 0.5s ease-in-out; /* EDITED HERE */
  box-shadow: 0 2px 5px rgba(255, 101, 80, 0.5);
}

.menu-btn-burger::before,
.menu-btn-burger::after {
  content: "";
  position: absolute;
  z-index: 0;
  width: 40px;
  height: 6px;
  background: white;
  /* border-radius: 5px; */
  transition: all 0.5s ease-in-out;
  box-shadow: 0 2px 5px rgba(255, 101, 80, 0.5);
}
/* Bottom Bar */
.menu-btn-burger::before {
  transform: translateY(-16px);
}
/* Top Bar */
.menu-btn-burger::after {
  transform: translateY(16px);
}

/* Animation */
/* Disappearing Middle Bar */
.menu-btn:checked + .menu-btn-burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
/* Rotation to X symbol */
.menu-btn:checked + .menu-btn-burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.menu-btn:checked + .menu-btn-burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.menu-btn:checked + .mobileNav {  
  visibility: visible !important;
}

.mobileNavIcon {
  z-index: 100;
  visibility: hidden; 
}

.mobileNav {
  position: fixed;
  height: 100%;
  width: 60%;
  left: -100%;
  top: 0;
  z-index: 99;
  border-radius: 0  15px 15px 0;
  visibility: visible; 

  background: rgba(114, 113, 113, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.9px);
  -webkit-backdrop-filter: blur(7.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-left: none;

  transition: ease-in-out 0.2s;
  animation-name: fadeInLeft;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.moileNavActive {
    position: fixed;
    height: 100%;
    width: 60%;
    left: 0px;
    top: 0;
    z-index: 99;
    border-radius: 0  15px 15px 0;
    visibility: visible;
}

.mobileNav > .triggered {
  visibility: visible;
}

.linkContaner {
  height: 70%;
  width: 80%;
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  margin-top: 70px;
}

@media only screen and (max-width: 480px) {

 .filterSidebar {
    position: fixed;
    height: 60% !important;
    top: 20% !important;
    left: -150px;
    width: 150px;
    border-radius: 15px;
    opacity: 0;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    z-index: 7;
  }

  .mobileNavIcon {
    visibility: visible;
  }

  .mobileNav {
    visibility: visible;
  }

  .desktopNav {
    visibility: hidden; 
    text-shadow: black;
  }

  .filterActive {
    opacity: 1 !important;
  }

  .sidebarContactForm {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    min-width: 100vw;
    background: rgba(255, 255, 255, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(6.9px);
    -webkit-backdrop-filter: blur(6.9px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    opacity: 1;
    z-index: 1;
  }
}

.navTestButton {
  z-index: 101;
}

.fadeInLeft {
  animation-name: fadeInLeft;
} 

  @keyframes fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(-20px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }

 .mobile-nav-link{
    font-size: 20px;
    letter-spacing: 1.5px;
    font-weight: lighter;
    padding-bottom: 5px;
    text-shadow: 0px 0px 6px rgba(0,0,0,0.75);
 }
 .home {
  font-weight: bold;
  letter-spacing: 1.5px;

 }

 .ccfotter {
  margin-top: 85px;
  text-align: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.482);
 }

 .filterSidebar {
    position: fixed;
    height: 50%;
    top: 25%;
    right: -150px;
    width: 150px;
    border-radius: 15px;
    opacity: 0;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
 }

 .filterActive {
    position: fixed;
    height: 50%;
    top: 25%;
    right: 35px;
    width: 150px;
    background: rgba(255, 255, 255, 0.31);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(6.9px);
    -webkit-backdrop-filter: blur(6.9px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    opacity: 0.5;
 }

 .filterSidebar:hover{
    opacity: 1;
 }
 
@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebarHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 10%;
  min-height: 25px;
  border-bottom: 1px solid rgba(249, 249, 249, 0.5);
  color: white;
  border-radius: 15px;
  background: rgba(59, 59, 59, 0.268);
  text-shadow: 0px 0px 6px rgba(0,0,0,0.75);

}

.sidebarTagContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: rgb(29, 29, 29);
  border-radius: 15px;
}

.sidebarSortContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: rgb(29, 29, 29);
  border-radius: 15px;
}

.sidebarResetContainer{
  position: relative;
  bottom: -10px;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnIconReset {
  width: 40%;
  height: 40%;
  opacity: 0.5;
  transition: 0.2s ease;
}

.btnIconReset:hover {
  color: white;
}


.sidebarSortSelectContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sortByDropdown {
  padding: 5px;
  border-radius: 15px;
  margin-top: 10px;
  opacity: 0.5;
}

.sortByDropdown:hover { 
  opacity: 1;
}


.tagContainerAside{ 
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.selectTag {
  width: 70%;
  padding: 5px;
  border-radius: 16px;
  background: #29292954;
  margin-top: 7px;
  color: white;
  display: flex;
  justify-content: space-between;
  transition: 0.2s ease;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
}



.blueDotSide {

}

.yellowDotSide{

}

.dotSide {
  color: rgba(185, 185, 185, 0.637);
  font-size: 15px;
}

.musicTagActive {
  background: rgba(57, 113, 191, 0.389);
}

.blogTagActive {
  background: rgba(149, 147, 10, 0.389);
}


.siteTagActive {
  background: rgba(255, 0, 0, 0.29);
}

.galleryTagActive {
  background: rgba(128, 0, 128, 0.389);

}

.musicDotActive {
  color: rgb(78, 103, 217);
}

.blogDotActive {
  color: rgb(255, 225, 0);
}

.siteDotActive {
  color: rgb(222, 26, 26);

}

.galleryDotActive {
  color: plum;

}

.hover-underline-animation {
  z-index: 10;
}

.hamburger-react > div {
  box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
}

.chatbotBtnContainer {
  z-index: 100;
}

.videoContainer {
  width: 100vw;
  height: 100vh;
}