.hamburger > .cursor-background  {   
    animation: fadeOut 1s cubic-bezier(0.77, 0, 0.175, 1) 0.2s forwards;
    transform: scale(2) !important;
}
  
.hamburger > .main-cursor-background  {   
    animation: scaleUp 1s cubic-bezier(0.77, 0, 0.175, 1) 0.2s forwards; 
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes bgUp {
    from {
      background-color: transparent;
    }
  
    to {
      background-color: black;
    }
  }
  
  @keyframes bgDown {
    from {
      background-color: black;
    }
  
    to {
      background-color: transparent;
    }
  }
  
  @keyframes scaleUp {
    from {
      transform: scale(1);
    }
  
    to {
      transform: scale(5);
    }
  }
  
  @keyframes scaleDown {
    from {
      transform: scale(5);
    }
  
    to {
      transform: scale(1);
    }
  }
  
  @keyframes translateLeftDot {
    from {
      transform: translate(20px, -50%);
    }
  
    to {
      transform: translate(0px, -50%);
    }
  }
  
  @keyframes translateRightDot {
    from {
      transform: translate(-20px, -50%);
    }
  
    to {
      transform: translate(0px, -50%);
    }
  }