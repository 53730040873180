:root{
    --lightgrey-text: rgb(211, 211, 211);
}

@media screen and (min-width: 380px) {
    .line {
        overflow: visible !important;
    }
}


@media screen and (max-width: 380px) {
    .heroHeading {
        font-size: 2.8em !important;
        margin: 30px;
    }

    .heroSubHeading {
        font-size: 3em !important;
        text-align: center;
        margin: 30px;
    }

    .sectionHeading {
        font-size: 1.5em !important;
        margin-bottom: 0.5em;
        font-weight: 400;
    }

    #sectionFour {
        display: flex;
        justify-content: start;
        flex-direction: column;
    
        opacity: 0;
    
        visibility: hidden;
    
        height: auto !important;
        margin-left: 30px;
        margin-top: 60px !important;
    }

    .heroHeadingCTA {
        width: 85vw;
        font-size: 1.6em !important;
        overflow: visible !important;
    }

    #sectionThree {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        
        width: 100% !important;
        padding: 30px;
        opacity: 0;
        margin: 0 !important;
    }

    .mainHeaedrLink {
        visibility: hidden !important;
        opacity: 0;
    }

    #contactLink {
        /* top: auto !important; */
        bottom: 22px !important;
        left: 20px !important;
        font-size: 15px;
    }
}

@media screen and (max-width: 800px) { 

    #sectionThree {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        
        width: 100% !important;
        padding: 30px;
        opacity: 0;
        margin: 0 !important;
    }

    .sectionText {
        font-size: 1.1em !important;
        line-height: 1.2em;
        font-family: Poppins;
        font-weight: 500;
    }

    .sectionHeading {
        font-size: 1.5em !important;
        margin-bottom: 0.5em;
        font-weight: 400;
    }

    .sectionTitle {
        font-size: 1em !important;
        margin-bottom: 0.5em; 	
        color: var(--lightgrey-text);
        font-family: "poppins";
    }

    .heroHeading {
        font-size: 1.5em !important;
        margin: 30px;
    }

    .heroSubHeading {
        font-size: 1em !important;
        text-align: center;
        margin: 30px;
    }
/* 
    .line {
        height: 90px !important;
    } */
    

}

@media screen and (max-width: 1200px) { 
    .heroHeading {
        font-size: 8vw !important;
        margin: 30px;
    }

    .heroSubHeading {
        font-size: 4vw !important;
        text-align: center;
        margin: 30px;
    }

    .line {
        overflow: visible !important;
    }
}

.htmlOverlayWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    background: none;
    top: 0;
    color: white;
}

.landingSection {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    color: white;
}

#sectionOne {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    font-family: "Dela Gothic One" !important;
    flex-direction: column;
}

.trademarkFooterContaienr {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.dub3LogoContainer {
    position: fixed;
    bottom: 50px;
    right: 50px;
    color: white !important;
    z-index: 10 ;
}

.dub3NavLinksContainer {
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 10;

    display: flex;
    flex-direction: row;
    transition: 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.mainHeaedrLink:hover > .dubDash {
    -webkit-text-fill-color: rgb(255, 255, 255);
    cursor: pointer;
}

.mainHeaedrLink:hover {
    cursor: pointer;
}

.secondHeaedrLink:hover {
    cursor: pointer;
}

.mainHeaedrLink {
    margin: 10px;
    font-size: 30px;
    transition-duration: 0.4s;
    text-decoration: none;
    color: white;
}

.secondHeaedrLink {
    margin: 15px;
}

.dubDash {
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 2px; 
}

.dubDash:hover {
    -webkit-text-fill-color: rgb(255, 255, 255);
}

.landingScrollToPlayNoticeWrapper {
    position: fixed;
    width: 100vw;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 60px;
    opacity: 0;
}

.scrollToPlayNoticeContainer {

}

.videoScrollAnimationLanding {
    height: 50px;
    width: 50px;
    opacity: .5;
}



.htmlOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background: none;
    top: 0;
    visibility: visible;
}

#sectionTwo {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 30%;
    margin: 60px;
    opacity: 0;
}

#sectionThree {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: auto ;
    text-align: left;
    width: 35%;
    padding: 30px;
    opacity: 0;
    margin-left: calc(60% - 30px);

    visibility: hidden;
}

.sectionText {
    font-size: 1.1vw !important;
    line-height: 1.2em;
    font-family: Poppins;
    font-weight: 500;
}

.sectionHeading {
    font-size: 1.5vw;
    margin-bottom: 0.5em;
    font-weight: 400;
}

.sectionTitle {
    font-size: 1vw;
    margin-bottom: 0.5em; 	
    color: var(--lightgrey-text);
    font-family: "poppins";
}

.detailsSectionContainer {
    margin-top: 2em;
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between

}

.detailsSection {
    width: 30%;
    font-size: 12px;
    padding-left: 5px;
    border-left: 2px solid white;
}

#sectionFour {
    display: flex;
    justify-content: start;
    flex-direction: column;

    opacity: 0;

    visibility: hidden;

    height: auto !important;
    margin-left: 40px;
    margin-top: 120px;

}

.heroHeadingCTA {
    text-align: left;
    font-size: 3vw;
    line-height: 1.2em;
    height: auto;
    overflow: visible !important;

} 

.textOutline {
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 2px;
}

#sectionChurch {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 30%;
    height: auto !important;
    margin: 60px;
    opacity: 0;

    visibility: hidden;
}

#sectionChurch > .sectionText {
    text-shadow: 0px 0px 7px rgba(150, 150, 150, .7);
}

.split-parent {
    overflow: hidden;
}

.split-child {
    display: inline-block;
}

.heroWelcomeHeading {
    font-size: 2vw !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 2px;
}

/* .line {
    width: 100%;
    height: 7vh;
    min-height: 60px;
    position: relative;
    overflow: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.heroHeading {
    position: absolute;
    text-align: center;
    font-size: 3vw;
   /*  text-shadow: 0px 0px 7px rgba(150, 150, 150, .25); */
    position: relative;
    overflow: hidden;
    opacity: 0;
    letter-spacing: -1px !important;
    color: white;
    mix-blend-mode: difference;
}  

.heroSubHeading {
    position: absolute;
    font-size: 1.2vw;
    font-weight: 200 !important;
    text-align: center;
}

.FiberCanvas {
    position: relative;
}

.fiberExperienceContainer {
    opacity: 1;
}


.indexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50px;
    position: fixed;
    right: 40px;
    bottom: 30px;
    opacity: 1;
    overflow: visible !important;

}

.dot {
    height: 15px;
    width: 5px;
    background-color: rgb(255, 255, 255);
    /* border-radius: 50%; */
    display: inline-block;
    transition: 0.5s ease all;
    transform: skew(-20deg);
    overflow: visible !important;
    box-shadow: 0px 0px 7px rgba(150, 150, 150, .7) !important;


  }

  .dotActive {
    height: 25px;
    width: 5px;

  }

  .dotInactive {
    background-color: grey;
  }

.arrow {
    position: relative;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 30px;

}

a.arrow,
span.arrow {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5;
}

a.arrow .arrow_icon,
span.arrow .arrow_icon {
  position: relative;
  margin-left: 0.5em;
}

a.arrow .arrow_icon svg,
span.arrow .arrow_icon svg {
  transition: transform 0.3s 0.02s ease;
  margin-right: 1em;
}

a.arrow .arrow_icon::before,
span.arrow .arrow_icon::before {
  content: "";
  display: block;
  position: absolute;
  top: 60%;
  left: 0;
  width: 0;
  height: 2px;
  background: #FFFFFF;
  transform: translateY(-50%);
  transition: width 0.3s ease;
}

a.arrow:hover .arrow_icon::before,
span.arrow:hover .arrow_icon::before {

  width: 1em;
}

a.arrow:hover .arrow_icon svg,
span.arrow:hover .arrow_icon svg {
  transform: translateX(0.75em);
}

.hover-link {
    margin: 0 auto;
    border: none;
    background: none;
    color: #fff;
    padding: 0.5rem 0 0 0;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin: 12px;
    overflow: visible !important;
    text-shadow: 0px 0px 7px rgba(150, 150, 150, .7);

  }
  
  .hover-link > span {
    overflow: hidden;
    display: block;
    position: relative;
    pointer-events: none;

  }
  
  .hover-link > span > span {
    display: inline-block;
    overflow: visible !important;
  }
  
  .hover-link > span > span:nth-of-type(1) {
    transition: 0.4s ease all;
    overflow: visible !important;

  }
  
  .hover-link > span > span:nth-of-type(2) {
    transition: 0.4s ease all;
    transition-delay: 0.07s;
    overflow: visible !important;

  }
  
  .hover-link > span > span > span {
    content: attr(data-text);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(200%);
    overflow: visible !important;

  }
  
  .hover-link:hover > span > span,
  .hover-link:focus > span > span {
    transform: translateY(-200%);
    overflow: visible !important;

  }
  
  #contactLink {
    position: fixed;
    right: 30px;
    top: 35px;
  }
  
  #aboutLink {
    position: fixed;
    right: 160px;
    top: 35px;
  }


  .navigationOverlay {
  }

/* Interactive scetion */
  
@media screen and (max-width: 479px) {
    .interactiveSectionOverlay {
        width: 100vw !important;
       /*  top: 40vh !important;  */
        overflow: visible !important;
        padding: 20px !important;
        padding-top: 2em !important;
    }

    .closeIconHitbox {
        position: fixed;
        right: 30px !important;
        top: 30px !important;
        width: 20px;
        height: 25px;
        z-index: 12;
        overflow: visible !important;
    }

    
    .interactiveContainerSubHeading {
        font-size: 1em !important;
    }

    .tapToInteractNotice { 
        bottom: 80px !important;
    
    }
}

@media screen and (max-width: 400px) {
    #aboutLink {
        opacity: 0;
        visibility: hidden;
    }
}

.interactiveSectionOverlay {
    display: flex;
    flex-direction: column;
   /*  justify-content: space-around; */
    opacity: 0;

    position: fixed;
    right: 0;
    top: 0;
    width: 25vw;
    height: 100vh;

    background: rgba(255, 255, 255, 1);
    border-radius: 16px 0px 0px 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 1000;
    padding: 2%;
    overflow-y: scroll !important;
}

.interactiveSectionTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.interactiveContainerText{
    margin-top: 1em;
}

.interactiveContainerSubHeading {
    font-size: 1vw;
}

.intSectNav {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.6vw;
}

.closeIconHitbox {
    position: fixed;
    right: 40px;
    top: 40px;
    width: 25px;
    height: 25px;
    z-index: 12;
}

.closeIconHitbox:hover > .textCloseIcon {
    transform: rotate(90deg);
    color: var(--lightgrey-text) !important;
    cursor: pointer;
}

.textCloseIcon {
    right: 40px;
    top: 40px;
    width: 25px;
    height: 25px;
    transition: 0.25s all ease;
    z-index: 11;
}

.tapToInteractNotice {
    position: fixed;
    width: 200px;
    height: 50px;
    bottom: 30px;
    left: calc(50% - (200px / 2));
    z-index: 5;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    background: rgba(74, 74, 74, 0.5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    color: white;

    font-size: 0.9em;
    padding: 5px;
    opacity: 0;
}

.tapAnimationTwo {
    width: 35px;
    height: 35px;
}

.line {
    font-kerning: none;
    opacity: 1;
    width: 100%;
    /* height: 7vh; */
   /*  min-height: 60px; */
    position: relative; 
   /*  overflow: hidden !important; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.word {
    font-kerning: none;
}
