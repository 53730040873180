:root {
    --spacing: 10vh;
}

.aboutPageWrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    color: white;
    padding: 30px;
    overflow: scroll !important;
    overflow-x: hidden !important;
}

.aboutHeader {
    font-size: 10vw;
    text-transform: uppercase;
    line-height: 100%;
    margin-top: var(--spacing);
}

.previewContainer {
    display: flex;
    flex-direction: row;
    margin-top: var(--spacing);
}

.previewTextContainer {
    width: 30%;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    padding: 30px 30px 0px 30px;
}

.aboutImage{ 
    width: 70%;
    height: 100%;
    border-radius: 25px;
}

.showroom {
    width: 100vw;
    height: 100vh;
    margin-top: var(--spacing);
    display: flex;
    justify-content: space-around;
}

.conceptPreviewImage {
    border-radius: 25px;
    width: 500px;
    height: 700px;
}

.aboutFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: var(--spacing);
    justify-content: space-between;
    font-size: 12px !important;
} 
