.family {
    display: flex;
    margin: 100px 0;
    transform: translate3d(0, 0, 0);
    &:hover {
      cursor: grab;
    }
    .family-item {
      min-width: 50vw;
      margin-left: 3.33333vw;
  
      &:first-of-type {
        margin-left: 0;
      }
    }
  
    .family-item-image {
      background-size:contain;
     /*  background-repeat: no-repeat; */
      background-position: center;
      border-radius: 25px;
  
      &:before {
        content: "";
        display: block;
        width: 50%;
        padding-top: 60%;
        pointer-events: none;
      }
    }
  }

