.noticeWrapper {
    width: 100vw;
    height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
    flex-direction: column;
    text-align: center;
}

.noticeIcon {
    width: 40px;
    height: 40px;
    color: gray;
}

.noticeWrapper > p {
    max-width: 300px;
    color: gray;
}

.noticeWrapper > h1 {
    color: gray;
}

.subPageContainer {
    width: 100vw;
    height: 100%;

    padding: 40px;
    padding-top: 120px;
    background: black;
    color: white !important;
    overflow: scroll !important;
    overflow-x: hidden !important;
    margin-bottom: 10vh;

}